import React from "react";
import { Typography } from "@material-ui/core";
import { UserContext } from "../contexts/UserProvider";
import MatchRow from "./MatchRow";
import { colors } from "./theme";

function DailyPreview(props) {
  const [state, dispatch] = React.useContext(UserContext);

  //Date as 01.01.1970
  function weekdayForDate(date) {
    var parts = date.split(".");
    var dt = new Date(
      parseInt(parts[2], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[0], 10)
    );
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let index = dt.getDay();
    let weekDay = days[index];
    return weekDay;
  }

  return (
    <div>
      <Typography
        style={{
          margin: "15px",
          marginLeft: "5px",
          paddingTop: "10px",
          fontSize: "30px",
          color: colors.lightColor1,
        }}
      >
        {weekdayForDate(props.matchDay.date)}, {props.matchDay.date}
      </Typography>
      {state.matches.map((match, index) =>
        match.showInUI && props.matchDay.dayNumber == match.matchDay ? (
          <MatchRow
            key={index}
            matchInfo={match}
            date={state.date}
            matchDayInfo={props.matchDay}
          />
        ) : null
      )}
    </div>
  );
}

export default DailyPreview;
