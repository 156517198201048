import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopy from "@mui/icons-material/ContentCopy";
import {
  Box,
  Card,
  IconButton,
  Button,
  Typography,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { colors, fonts } from "./theme";
import { UserContext } from "../contexts/UserProvider";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {},
  "& .MuiDialogActions-root": {},
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  useEffect(() => {
    document.body.style.overflowY = "scroll";
    document.body.style.paddingRight = "0px";

    return () => {};
  }, []);

  return (
    <DialogTitle sx={{ m: 0, p: "20px" }} {...other}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        {children}
        {onClose ? (
          <Box
            style={{
              alignSelf: "flex-end",
              marginLeft: "auto",
              alignItems: "center",
            }}
          >
            <IconButton
              aria-label="close"
              onClick={onClose}
              style={{ transform: "scale(1.2)" }}
              sx={{
                color: colors.lightColor1,
                mb: 1,
                pt: "12px",
              }}
            >
              <CloseIcon style={{ color: colors.lightColor1 }} />
            </IconButton>
          </Box>
        ) : null}
      </Box>
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const theme = {
  elementInMatchRow: {
    paddingLeft: "20px",
    fontSize: "20px",
  },
};

export default function SRTDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [state, dispatch] = React.useContext(UserContext);

  const useStyles = makeStyles(theme);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        style={{
          minWidth: "150px",
          background: colors.mediumColor,
          fontSize: "7px",
          color: colors.lightColor1,
        }}
        onClick={handleClickOpen}
        startIcon={
          <ContentCopy style={{ marginBottom: "4px", paddingTop: "5px" }} />
        }
      >
        <Typography
          style={{
            fontFamily: fonts.medium,
            fontSize: "13px",
            paddingTop: "5px",
          }}
        >
          SRT Link
        </Typography>
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={"700px"}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{
            background: colors.linearBackground,
            textShadow: "0px 0px 40px white",
            minWidth: "600px",
          }}
        >
          <Typography
            style={{
              color: colors.lightColor1,
              fontFamily: fonts.medium,
              textShadow: `0px 0px 40px ${colors.lightColor1}`,
              fontSize: "20px",
              paddingTop: "7px",
            }}
          >
            {" "}
            Distribution {props.matchInfo.channel}: {props.matchInfo.home} -{" "}
            {props.matchInfo.away}
          </Typography>
        </BootstrapDialogTitle>
        <Box
          style={{
            height: "100%",
            width: "100%",
            background: colors.linearBackground,
            alignSelf: "center",
            borderRadius: "0px",
          }}
        >
          <Card
            style={{
              margin: "10px",
              padding: "20px",
              minWidth: "600px",
              borderRadius: "10px",
            }}
          >
            <Typography style={{ fontFamily: fonts.bold, fontSize: "17px" }}>
              SRT Link{" "}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  wordWrap: "break-word",
                  paddingTop: "5px",
                  marginRight: "25px",
                  fontFamily: fonts.thin,
                }}
              >
                {typeof state.userData !== "undefined"
                  ? state.userData.srtData[`Channel_${props.matchInfo.channel}`]
                      .SRT_Link
                  : null}
              </Typography>
              <Button
                variant="outlined"
                style={{
                  borderColor: colors.mediumColor,
                  color: colors.mediumColor,
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    state.userData.srtData[`Channel_${props.matchInfo.channel}`]
                      .SRT_Link
                  );
                }}
                startIcon={
                  <ContentCopy
                    style={{ marginBottom: "4px", paddingTop: "5px" }}
                  />
                }
              >
                <Typography
                  style={{
                    fontFamily: fonts.bold,
                    fontSize: "12px",
                    paddingTop: "5px",
                  }}
                >
                  Copy
                </Typography>
              </Button>
            </Box>
          </Card>
          <Card
            style={{
              margin: "10px",
              padding: "20px",
              minWidth: "600px",
              borderRadius: "10px",
            }}
          >
            <Typography style={{ fontFamily: fonts.bold, fontSize: "17px" }}>
              DNS{" "}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  wordWrap: "break-word",
                  paddingTop: "5px",
                  fontFamily: fonts.thin,
                }}
              >
                {typeof state.userData !== "undefined"
                  ? state.userData.srtData[`Channel_${props.matchInfo.channel}`]
                      .DNS
                  : null}
              </Typography>
              <Button
                variant="outlined"
                style={{
                  borderColor: colors.mediumColor,
                  color: colors.mediumColor,
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    state.userData.srtData[`Channel_${props.matchInfo.channel}`]
                      .DNS
                  );
                }}
                startIcon={
                  <ContentCopy
                    style={{ marginBottom: "4px", paddingTop: "5px" }}
                  />
                }
              >
                <Typography
                  style={{
                    fontFamily: fonts.bold,
                    fontSize: "12px",
                    paddingTop: "5px",
                  }}
                >
                  Copy
                </Typography>
              </Button>
            </Box>
          </Card>
          <Card
            style={{
              margin: "10px",
              padding: "20px",
              minWidth: "600px",
              borderRadius: "10px",
            }}
          >
            <Typography style={{ fontFamily: fonts.bold, fontSize: "17px" }}>
              Port{" "}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  wordWrap: "break-word",
                  paddingTop: "5px",
                  fontFamily: fonts.thin,
                }}
              >
                {typeof state.userData !== "undefined"
                  ? state.userData.srtData[`Channel_${props.matchInfo.channel}`]
                      .Port
                  : null}
              </Typography>
              <Button
                variant="outlined"
                style={{
                  borderColor: colors.mediumColor,
                  color: colors.mediumColor,
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    state.userData.srtData[`Channel_${props.matchInfo.channel}`]
                      .Port
                  );
                }}
                startIcon={
                  <ContentCopy
                    style={{ marginBottom: "4px", paddingTop: "5px" }}
                  />
                }
              >
                <Typography
                  style={{
                    fontFamily: fonts.bold,
                    fontSize: "12px",
                    paddingTop: "5px",
                  }}
                >
                  Copy
                </Typography>
              </Button>
            </Box>
          </Card>
          <Card
            style={{
              margin: "10px",
              padding: "20px",
              minWidth: "600px",
              borderRadius: "10px",
            }}
          >
            <Typography style={{ fontFamily: fonts.bold, fontSize: "17px" }}>
              Mode & Latency
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  wordWrap: "break-word",
                  paddingTop: "5px",
                  fontFamily: fonts.thin,
                }}
              >
                {typeof state.userData !== "undefined"
                  ? state.userData.srtData[`Channel_${props.matchInfo.channel}`]
                      .Mode
                  : null}{" "}
                {" - "} Min. 100ms
              </Typography>
            </Box>
          </Card>
          <Card
            style={{
              margin: "10px",
              padding: "20px",
              minWidth: "600px",
              borderRadius: "10px",
            }}
          >
            <Typography style={{ fontFamily: fonts.bold, fontSize: "17px" }}>
              Passphrase (16/128 Key Length)
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  wordWrap: "break-word",
                  paddingTop: "5px",
                  fontFamily: fonts.thin,
                }}
              >
                {typeof state.userData !== "undefined"
                  ? state.userData.srtData[`Channel_${props.matchInfo.channel}`]
                      .Passphrase
                  : null}
              </Typography>
              <Button
                variant="outlined"
                style={{
                  borderColor: colors.mediumColor,
                  color: colors.mediumColor,
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    state.userData.srtData[`Channel_${props.matchInfo.channel}`]
                      .Passphrase
                  );
                }}
                startIcon={
                  <ContentCopy
                    style={{ marginBottom: "4px", paddingTop: "5px" }}
                  />
                }
              >
                <Typography
                  style={{
                    fontFamily: fonts.bold,
                    fontSize: "12px",
                    paddingTop: "5px",
                  }}
                >
                  Copy
                </Typography>
              </Button>
            </Box>
          </Card>
          {typeof state.userData !== "undefined" &&
          state.userData.srtData[`Channel_${props.matchInfo.channel}`]
            .ShowBackupLink ? (
            <Card
              style={{
                margin: "10px",
                padding: "20px",
                minWidth: "600px",
                borderRadius: "10px",
              }}
            >
              <Typography style={{ fontFamily: fonts.bold, fontSize: "17px" }}>
                Backup Link{" "}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    wordWrap: "break-word",
                    paddingTop: "5px",
                    marginRight: "5px",
                    fontFamily: fonts.thin,
                  }}
                >
                  {typeof state.userData !== "undefined"
                    ? state.userData.srtData[
                        `Channel_${props.matchInfo.channel}`
                      ].Backup_Link
                    : null}
                </Typography>
                <Button
                  variant="outlined"
                  style={{
                    borderColor: colors.mediumColor,
                    color: colors.mediumColor,
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      state.userData.srtData[
                        `Channel_${props.matchInfo.channel}`
                      ].Backup_Link
                    );
                  }}
                  startIcon={
                    <ContentCopy
                      style={{ marginBottom: "4px", paddingTop: "5px" }}
                    />
                  }
                >
                  <Typography
                    style={{
                      fontFamily: fonts.bold,
                      fontSize: "12px",
                      paddingTop: "5px",
                    }}
                  >
                    Copy
                  </Typography>
                </Button>
              </Box>
            </Card>
          ) : null}
        </Box>
      </BootstrapDialog>
    </div>
  );
}
