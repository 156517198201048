import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Card, Grid } from "@material-ui/core";
import { colors, fonts } from "./theme";
import logo_black from "../assets/logo_black.png";
import { useAuth } from "../contexts/AuthContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {},
  "& .MuiDialogActions-root": {},
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  useEffect(() => {
    document.body.style.overflowY = "scroll";
    document.body.style.paddingRight = "0px";

    return () => {};
  }, []);
  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        {children}
        {onClose ? (
          <Box
            style={{
              alignSelf: "flex-end",
              marginLeft: "auto",
              alignItems: "center",
            }}
          >
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                right: 7,
                transform: "scale(1.2)",
                mb: 1,
                color: colors.mediumColor,
              }}
            >
              <CloseIcon style={{ color: colors.mediumColor }} />
            </IconButton>
          </Box>
        ) : null}
      </Box>
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function InfoDialog(props) {
  const [open, setOpen] = React.useState(false);
  const { currentUser } = useAuth();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        style={{
          margin: "15px",
          paddingTop: "10px",
          minWidth: "6%",
          height: "50px",
          background: colors.lightColor1,
          fontSize: "14px",
          color: colors.darkColor1,
          opacity: "95%",
          boxShadow: `1px 1px 5px ${colors.lightColor1}`,
        }}
        onClick={handleClickOpen}
        startIcon={<InfoIcon style={{ marginBottom: "4px" }} />}
      >
        <Typography style={{ fontFamily: fonts.medium }}>
          Information
        </Typography>
      </Button>
      <BootstrapDialog onClose={handleClose} open={open}>
        <Card
          style={{
            minWidth: "500px",
            padding: "10px",
            backgroundColor: colors.lightColor1,
          }}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <Typography style={{ fontFamily: fonts.medium }}>
              Information
            </Typography>
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <Typography stcomyle={{ fontFamily: fonts.medium }}>
              Contact:{" "}
              <Typography style={{ fontFamily: fonts.thin }}>
                fwc2022-support@hbs.tv
              </Typography>
            </Typography>
            <Typography style={{ fontFamily: fonts.medium }}>
              Username:
              <Typography style={{ fontFamily: fonts.thin }}>
                {currentUser.email}
              </Typography>
            </Typography>
            <Typography style={{ fontFamily: fonts.medium }}>
              Session ID:{" "}
              <Typography style={{ fontFamily: fonts.thin }}>
                {localStorage.getItem("SessionID")}
              </Typography>
            </Typography>
          </DialogContent>
          <Box display="flex" justifyContent="center" flexWrap="wrap">
            <img
              src={logo_black}
              alt="Logo"
              style={{ maxWidth: "30%", minWidth: "25%", padding: "20px" }}
            />
          </Box>
        </Card>
      </BootstrapDialog>
    </div>
  );
}
