import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { UserContext } from "../contexts/UserProvider";
import { db } from "../firebase";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { colors, fonts } from "./theme";
import Button from "@material-ui/core/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import background from "../assets/background_2.png";
import InfoDialog from "./InfoDailog";
import { Box, Typography } from "@material-ui/core";
import DailyPreview from "./DailyPreview";
import logo_white from "../assets/logo_white.png";
import { publicIpv4, publicIpv6 } from "public-ip";
import { arrayUnion, doc, setDoc, arrayRemove } from "firebase/firestore";

export default function Dashboard() {
  const [error, setError] = useState("");
  const { currentUser, logout, getToken } = useAuth();
  const history = useHistory();
  const [state, dispatch] = React.useContext(UserContext);

  const theme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: colors.mediumColor,
      },
      secondary: {
        main: "#8A1538",
      },
      background: {
        paper: colors.lightColor1,
      },
      action: {
        disabled: colors.darkColor1,
      },
      error: {
        main: "#B6B5B2",
      },
    },

    typography: {
      fontFamily: fonts.medium,
    },
  });

  useEffect(() => {
    document.body.style.backgroundColor = colors.mediumColor;
    document.body.style.backgroundImage = `url(${background})`;
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundAttachment = "fixed";

    dispatch({
      type: "setReachedUI",
      value: true,
    });

    startTime();
    const fbListener = subscribeToUserDB();
    const fetchTournamentData = tournamentData();
    const fetchStreamingConfig = streamingConfig();
    setIP();

    return () => {
      fbListener();
      fetchTournamentData();
      fetchStreamingConfig();
    };
  }, []);

  async function setIP() {
    let ipV6 = await publicIpv6();
    let ipV4 = await publicIpv4();
    const today = new Date();

    db.collection("analytics")
      .doc("logins")
      .update({
        allLogins: arrayUnion({
          ipV4: ipV4,
          ipV6: ipV6,
          timestamp: today,
          username: currentUser.email,
        }),
      });
  }

  function startTime() {
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const today = new Date();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    let weekday = days[today.getDay()];
    let year = today.getFullYear();
    let h = today.getHours();
    let m = today.getMinutes();
    m = checkTime(m);
    let convertDay = checkTime(day);
    let convertmonth = checkTime(month);

    let time = h + ":" + m;
    let date = convertDay + "." + convertmonth + "." + year;

    dispatch({
      type: "updateTime",
      value: time,
    });

    dispatch({
      type: "updateDate",
      value: date,
    });

    dispatch({
      type: "updateWeekday",
      value: weekday,
    });
  }

  function checkTime(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  function subscribeToUserDB() {
    const firebaseListener = db
      .collection("tournament")
      .doc("matches")
      .onSnapshot((doc) => {
        let fireBaseData = doc.data();
        dispatch({
          type: "updateMatches",
          value: fireBaseData.matches,
        });
      });
    return firebaseListener;
  }

  function streamingConfig() {
    const firebaseListener = db
      .collection("tournament")
      .doc("streamingConfig")
      .onSnapshot((doc) => {
        let streamingConfig = doc.data();

        dispatch({
          type: "updateStreamingConfig",
          value: streamingConfig,
        });
      });
    return firebaseListener;
  }

  function tournamentData() {
    const firebaseListener = db
      .collection("tournament")
      .doc("matchdays")
      .onSnapshot((doc) => {
        let tournamentData = doc.data();

        dispatch({
          type: "updateTournamentData",
          value: tournamentData,
        });
      });
    return firebaseListener;
  }

  function kickSession() {
    let sessionToRemove = localStorage.getItem("SessionID");
    let string;
    if (state.userData["session_1"].id == sessionToRemove) {
      string = "session_1";
    } else {
      string = "session_2";
    }

    let newSession = {
      id: "free",
      timestamp: "n/a",
      ip: "n/a",
    };
    db.collection("users")
      .doc(currentUser.uid)
      .update({
        [string]: newSession,
      });

    localStorage.removeItem("SessionID");
  }

  async function handleLogout() {
    localStorage.removeItem("SessionID");
    dispatch({
      type: "setReachedUI",
      value: false,
    });
    setError("");
    try {
      await logout();
      history.push("/login");
    } catch {
      setError("Failed to log out");
    }
  }

  function handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  return (
    <div>
      <ThemeProvider theme={theme}>
        <div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
            }}
          >
            <Button
              style={{
                margin: "15px",
                paddingTop: "10px",
                minWidth: "12%",
                background: colors.lightColor1,
                fontSize: "15px",
                height: "50px",
                boxShadow: `1px 1px 5px ${colors.lightColor1}`,
              }}
              startIcon={<LogoutIcon style={{ marginBottom: "4px" }} />}
              onClick={() => {
                kickSession();
                handleLogout();
              }}
            >
              Sign out
            </Button>
            <InfoDialog />
            <Box
              style={{
                color: colors.lightColor1,
                alignSelf: "center",
                marginLeft: "auto",
                paddingBottom: "10px",
                marginTop: "5px",
                maxWidth: "30%",
                minWidth: "350px",
                paddingLeft: "85px",
              }}
            >
              <img
                src={logo_white}
                alt="Logo"
                style={{ maxWidth: "30%", minWidth: "25%" }}
              />
            </Box>
            <Box
              style={{
                color: colors.lightColor1,
                alignSelf: "flex-end",
                marginLeft: "auto",
                paddingRight: "20px",
                paddingBottom: "30px",
              }}
            >
              <Typography
                style={{
                  color: colors.lightColor1,
                  fontSize: "25px",
                }}
              >
                RIS Streaming
              </Typography>
            </Box>
          </Box>

          {typeof state.tournamentData !== "undefined"
            ? state.tournamentData.matchDays.map((matchDay, index) =>
                matchDay.showInUI ? (
                  <DailyPreview matchDay={matchDay} key={index}></DailyPreview>
                ) : null
              )
            : null}
        </div>
      </ThemeProvider>
    </div>
  );
}
