export const reducer = (state, action) => {
  switch (action.type) {
    case "updateMatches":
      return {
        ...state,
        matches: action.value,
      };
    case "updateUserData":
      return {
        ...state,
        userData: action.value,
      };

    case "updateTournamentData":
      return {
        ...state,
        tournamentData: action.value,
      };
    case "triggerPlayer":
      return {
        ...state,
        openPlayer: action.value,
      };

    case "updateTime":
      return {
        ...state,
        time: action.value,
      };
    case "updateDate":
      return {
        ...state,
        date: action.value,
      };
    case "updateWeekday":
      return {
        ...state,
        weekday: action.value,
      };
    case "updateStreamingConfig":
      return {
        ...state,
        streamingConfig: action.value,
      };
    case "setReachedUI":
      return {
        ...state,
        reachedUI: action.value,
      };
    default:
      return state;
  }
};

export const initialState = {
  matches: [],
  openPlayer: false,
  userData: undefined,
  time: "",
  date: "",
  weekday: "",
  tournamentData: undefined,
  streamingConfig: {},
  reachedUI: false,
};
