import Dialog from "@mui/material/Dialog";
import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { UserContext } from "../contexts/UserProvider";
import { Card, Typography, Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "./theme";
import { forwardRef, useImperativeHandle } from "react";
import Videoplayer from "./Videoplayer";
import Countdown from "./Countdown";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = {
  elementInMatchRow: {
    fontSize: "20px",
    paddingTop: "15px",
    marginLeft: "3px",
    color: colors.darkColor1,
  },
  elementInMatchRow2: {
    fontSize: "20px",
    paddingTop: "15px",
    paddingLeft: "20px",
    paddingRight: "20px",
    color: colors.darkColor1,
  },
};

export default forwardRef(function FullScreenPlayer(props, ref) {
  const [open, setOpen] = React.useState(false);
  const [ready, setReady] = React.useState(false);
  const [state, dispatch] = React.useContext(UserContext);

  const useStyles = makeStyles(theme);
  const classes = useStyles();

  useImperativeHandle(
    ref,
    () => ({
      handleOpenFromOutside(value) {
        setOpen(value);
      },
    }),
    []
  );

  useEffect(() => {
    return () => {};
  }, [state]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const goLive = () => {
    setReady(true);
    props.goLive();
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: "relative",
            background: colors.linearBackground,
          }}
        >
          <Toolbar
            style={{
              backgroundColor: colors.lightColor1,
              opacity: "95%",
              marginLeft: "15px",
              marginTop: "15px",
              marginBottom: "15px",
              borderRadius: "10px",
            }}
          >
            <IconButton
              edge="closing"
              onClick={handleClose}
              aria-label="close"
              style={{
                color: colors.darkColor1,
                transform: "scale(1.2)",
                padding: "5px",
              }}
            >
              <CloseIcon />
            </IconButton>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "50px",
                flexWrap: "wrap",
                paddingBottom: "12px",
                marginLeft: "60px",
              }}
            >
              <Typography className={classes.elementInMatchRow}>
                Match {props.matchInfo.matchID}
              </Typography>
              <Typography className={classes.elementInMatchRow}>
                {props.matchInfo.estKickOff}{" "}
                <a
                  style={{
                    display: "inline-block",
                    transform: "translateY(-5px)",
                    fontSize: "15px",
                  }}
                >
                  (CET)
                </a>
              </Typography>
              <Typography className={classes.elementInMatchRow}>
                Stadium: {props.matchInfo.stadium}
              </Typography>
              <Typography className={classes.elementInMatchRow}>
                Distribution: {props.matchInfo.channel}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  gap: "20px",
                }}
              >
                <Typography className={classes.elementInMatchRow2}>
                  {props.matchInfo.home}
                </Typography>
                <div>
                  <img
                    src={require(`../assets/flags/${props.matchInfo.home}.png`)}
                    alt={props.matchInfo.home}
                    width="70rem"
                    style={{ paddingTop: "20px" }}
                  ></img>
                </div>
                <Typography
                  style={{ paddingTop: "15px", color: colors.darkColor1 }}
                >
                  :
                </Typography>
                <div>
                  <img
                    src={require(`../assets/flags/${props.matchInfo.away}.png`)}
                    alt={props.matchInfo.away}
                    width="70rem"
                    style={{ paddingTop: "20px" }}
                  ></img>
                </div>
                <Typography className={classes.elementInMatchRow2}>
                  {props.matchInfo.away}
                </Typography>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          style={{
            height: "100%",
            width: "100%",
            backgroundImage: `url(${require(`../assets/background_2.png`)})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            alignSelf: "center",
          }}
        >
          {ready ? (
            <Videoplayer matchInfo={props.matchInfo}></Videoplayer>
          ) : (
            <Countdown
              goLiveBaby={goLive}
              matchInfo={props.matchInfo}
              randomNum={Math.floor(Math.random() * 90000)}
            ></Countdown>
          )}
        </Box>
      </Dialog>
    </div>
  );
});
