import { Card, Typography, Box, Button } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SRTDialog from "./SRTDialog";
import { UserContext } from "../contexts/UserProvider";
import FullScreenPlayer from "./FullScreenPlayer";
import { useState, useEffect, useRef } from "react";
import { colors } from "./theme";

const theme = {
  elementInMatchRow: {
    fontSize: "12px",
    paddingTop: "8px",
    marginLeft: "3px",
  },

  elementInMatchRow2: {
    fontSize: "12px",
    minWidth: "110px",
    paddingTop: "8px",
  },

  disabledText: {
    color: colors.darkColor1,
  },
};
export default function MatchWindow(props) {
  const fref = useRef();
  const useStyles = makeStyles(theme);
  const classes = useStyles();
  const [active, setActive] = React.useState(false);
  const [live, setLive] = React.useState(false);

  const handleClick = (value) => {
    fref.current.handleOpenFromOutside(value);
  };

  useEffect(() => {
    evaluateMatch();
    return () => {};
  }, []);

  function evaluateMatch() {
    let matchDay = props.matchDayInfo;
    let date = props.date;
    let dateOfMatch = props.matchInfo.date;
    let startTime = props.matchInfo.kickOff + ":00";
    var parts = dateOfMatch.split(".");
    let year = parseInt(parts[2], 10);
    let month = parseInt(parts[1], 10);
    let day = parseInt(parts[0], 10);
    // Get today's date and time
    var countDownDate = new Date(
      `${month}/${day}/${year} ${startTime}`
    ).getTime();
    let x = new Date();
    var now = x.getTime();

    var distance = countDownDate - now;
    if (distance < 0 && !active) {
      setActive(true);
    }

    if (matchDay.date == date && !active) {
      setActive(true);
    }

    if (matchDay.date == date && !active) {
      setActive(true);
    }

    if (live && !active) {
      setActive(true);
    }
  }

  function goLive() {
    setLive(true);
  }

  return (
    <div>
      <Card
        style={{
          marginTop: "10px",
          marginLeft: "5px",
          boxShadow: !active ? null : `1px 1px 7px ${colors.lightColor1}`,
          opacity: !active ? "75%" : "95%",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
          style={{ width: "100%" }}
        >
          {!active ? null : (
            <FullScreenPlayer
              matchInfo={props.matchInfo}
              ref={fref}
              goLive={goLive}
            ></FullScreenPlayer>
          )}
          <Button
            disabled={!active}
            style={{ width: "100%" }}
            onClick={() => handleClick(true)}
            className={!active ? classes.disabledText : null}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                gap: "30px",
                flexWrap: "wrap",
              }}
              style={{ width: "100%" }}
            >
              <Typography
                className={classes.elementInMatchRow}
                style={{ paddingLeft: "30px" }}
              >
                Match {props.matchInfo.matchID}
              </Typography>
              <Typography className={classes.elementInMatchRow}>
                {props.matchInfo.estKickOff}{" "}
                <a
                  style={{
                    display: "inline-block",
                    transform: "translateY(-5px)",
                    fontSize: "12px",
                  }}
                >
                  (CET)
                </a>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Typography className={classes.elementInMatchRow2}>
                  {props.matchInfo.home}
                </Typography>
                <div>
                  <img
                    src={require(`../assets/flags/${props.matchInfo.home}.png`)}
                    alt={props.matchInfo.home}
                    width="70rem"
                    style={{ paddingTop: "10px" }}
                  ></img>
                </div>
                <Typography style={{ padding: "10px" }}>:</Typography>
                <div>
                  <img
                    src={require(`../assets/flags/${props.matchInfo.away}.png`)}
                    alt={props.matchInfo.away}
                    width="70rem"
                    style={{ paddingTop: "10px" }}
                  ></img>
                </div>
                <Typography className={classes.elementInMatchRow2}>
                  {props.matchInfo.away}
                </Typography>
              </Box>
              <Typography className={classes.elementInMatchRow}>
                {props.matchInfo.stadium}
              </Typography>
              <Typography
                className={classes.elementInMatchRow}
                style={{
                  alignSelf: "flex-end",
                  marginLeft: "auto",
                  paddingRight: "50px",
                  paddingBottom: "20px",
                }}
              >
                Distribution: {props.matchInfo.channel}
              </Typography>
            </Box>
          </Button>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "20px",
            }}
          >
            <SRTDialog matchInfo={props.matchInfo}></SRTDialog>
          </Box>
        </Box>
      </Card>
    </div>
  );
}
