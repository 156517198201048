import React, { useState } from "react";
import { Card } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import { UserContext } from "../contexts/UserProvider";
import { colors } from "./theme";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { db } from "../firebase";
import { publicIpv4 } from "public-ip";

function SessionDialog(props) {
  const [state, dispatch] = React.useContext(UserContext);
  const history = useHistory();
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();

  async function handleLogout() {
    setError("");
    try {
      await logout();
      history.push("/login");
    } catch {
      setError("Failed to log out");
    }
  }

  async function kickSession(sessionToRemove) {
    let sessionID = localStorage.getItem("SessionID");
    const today = new Date();
    let textDate = today.toString();
    const IP = await publicIpv4();

    let newSession = {
      id: sessionID,
      timestamp: textDate,
      ip: IP,
    };

    db.collection("users")
      .doc(currentUser.uid)
      .update({
        [sessionToRemove]: newSession,
      });
  }

  return (
    <div>
      <Card
        style={{
          textAlign: "center",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          opacity: "95%",
          width: "50%",
          backgroundColor: colors.lightColor1,
          marginTop: "100px",
          borderRadius: "10px",
        }}
      >
        {typeof state.userData !== "undefined" ? (
          <div>
            <Card
              style={{
                marginBottom: "5px",
                backgroundColor: colors.lightColor1,
              }}
            >
              <h3>Session 1: {state.userData["session_1"].id}</h3>
              <p>Timestamp: {state.userData["session_1"].timestamp}</p>

              <Button
                style={{
                  margin: "15px",
                  paddingTop: "10px",
                  minWidth: "12%",
                  background: colors.mediumColor,
                  color: colors.lightColor1,
                  fontSize: "15px",
                  height: "50px",
                  boxShadow: "1px 1px 5px #888888",
                }}
                onClick={() => {
                  kickSession("session_1");
                }}
              >
                Kick Session 1
              </Button>
            </Card>
            <Card
              style={{
                marginBottom: "5px",
                backgroundColor: colors.lightColor1,
              }}
            >
              <Box>
                <h3>Session 2: {state.userData["session_2"].id}</h3>
                <p>Timestamp: {state.userData["session_2"].timestamp}</p>

                <Button
                  style={{
                    margin: "15px",
                    paddingTop: "10px",
                    minWidth: "12%",
                    background: colors.mediumColor,
                    color: colors.lightColor1,
                    fontSize: "15px",
                    height: "50px",
                    boxShadow: "1px 1px 5px #888888",
                  }}
                  onClick={() => {
                    kickSession("session_2");
                  }}
                >
                  Kick Session 2
                </Button>
              </Box>
            </Card>
          </div>
        ) : null}
        <Box>
          <Button
            style={{
              margin: "55px",
              paddingTop: "10px",
              minWidth: "138px",
              background: colors.mediumColor,
              color: colors.lightColor1,
              fontSize: "15px",
              height: "50px",
              boxShadow: "1px 1px 5px #888888",
            }}
            startIcon={<LogoutIcon style={{ marginBottom: "4px" }} />}
            onClick={handleLogout}
          >
            Exit
          </Button>
        </Box>
      </Card>
    </div>
  );
}

export default SessionDialog;
