export const colors = {
  lightColor1: "#EEEEE4",
  mediumColor: "#8A1538",
  darkColor1: "#1C0111",
  mediumAlert1: "#FFBF00",
  mediumAlert2: "#FF4700",
  linearBackground:
    "linear-gradient(90deg, rgba(28,1,17,1) 0%, rgba(138,21,56,1) 80%)",
};

export const fonts = {
  medium: "Qatar2022-Medium",
  bold: "Qatar2022-Bold",
  thin: "Qatar2022-thin",
};
