import React, { useState, useEffect } from "react";
import logo_white from "../assets/logo_white.png";
import { Card, CardContent, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import { UserContext } from "../contexts/UserProvider";
import { colors } from "./theme";

function Countdown(props) {
  const [remainingTime, setRemTime] = React.useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const downCounter = startCountdown();

    return () => {
      clearInterval(downCounter);
    };
  }, []);

  function startCountdown() {
    const downCounter = setInterval(() => calculateRemTim(downCounter), 1000);
    return downCounter;
  }
  function calculateRemTim(interval) {
    let startTime = props.matchInfo.kickOff + ":00";
    let date = props.matchInfo.date;
    var parts = date.split(".");
    let year = parseInt(parts[2], 10);
    let month = parseInt(parts[1], 10);
    let day = parseInt(parts[0], 10);
    // Get today's date and time - 90 seconds countdown randomizer
    var countDownDate =
      new Date(`${month}/${day}/${year} ${startTime}`).getTime() - 900000;

    let x = new Date();
    var now = x.getTime() + x.getTimezoneOffset() * 60 * 1000;

    // Find the distance between now and the count down date
    //var distance = countDownDate - now;
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    //var remDays = Math.floor(distance / (1000 * 60 * 60 * 24));

    let remainingCustomTime = distance - props.randomNum;
    var remHours = Math.floor(
      (remainingCustomTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var remMinutes = Math.floor(
      (remainingCustomTime % (1000 * 60 * 60)) / (1000 * 60)
    );
    var remSeconds = Math.floor((remainingCustomTime % (1000 * 60)) / 1000);
    // If the count down is finished, write some text

    //more random joing on webrtc server
    if (remainingCustomTime < 0) {
      clearInterval(interval);
      props.goLiveBaby();
    }

    setRemTime({ hours: remHours, minutes: remMinutes, seconds: remSeconds });
  }

  return (
    <div>
      <Box style={{ paddingTop: "160px" }}>
        <Box
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          style={{ margin: "40px" }}
        >
          <img
            src={logo_white}
            alt="Logo"
            style={{ maxWidth: "12%", minWidth: "10%" }}
          />
        </Box>
        <Typography
          style={{
            fontSize: "25px",
            textAlign: "center",
            color: colors.lightColor1,
            textShadow: "0px 0px 100px white",
          }}
        >
          Stream will start in...
        </Typography>
        <Typography
          style={{
            textAlign: "center",
            color: colors.lightColor1,
            fontSize: "35px",
            textShadow: "0px 0px 160px white",
          }}
        >
          {remainingTime.hours} Hours
        </Typography>
        <Typography
          style={{
            textAlign: "center",
            color: colors.lightColor1,
            fontSize: "35px",
            textShadow: "0px 0px 100px white",
          }}
        >
          {remainingTime.minutes} Minutes
        </Typography>
        <Typography
          style={{
            textAlign: "center",
            color: colors.lightColor1,
            fontSize: "35px",
            textShadow: "0px 0px 90px white",
          }}
        >
          {remainingTime.seconds} Seconds
        </Typography>
      </Box>
    </div>
  );
}

export default Countdown;
