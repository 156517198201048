import { UserContext } from "../contexts/UserProvider";
import { db } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import Dashboard from "./Dashboard";
import SessionDialog from "./SessionDialog";
import background from "../assets/background_2.png";
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { publicIpv4 } from "public-ip";
import CircularProgress from "@mui/material/CircularProgress";
import { colors } from "./theme";
import Stack from "@mui/material/Stack";

function SessionManager(props) {
  const [userIsAllowed, setUserIsAllowed] = useState(false);
  const [loadedUserData, setLoadedUserData] = useState(false);
  const [state, dispatch] = React.useContext(UserContext);
  const { currentUser, logout } = useAuth();
  const history = useHistory();
  const [error, setError] = useState("");
  const stateRef = useRef();
  stateRef.current = state;

  useEffect(() => {
    document.body.style.backgroundColor = "rgb(28,1,17)";
    document.body.style.backgroundImage = `url(${background})`;
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundAttachment = "fixed";
    document.body.style.overflowY = "scroll";
    const userDataListener = subscribeToUserData();

    return () => {
      userDataListener();
    };
  }, []);

  function subscribeToUserData() {
    const firebaseListener = db
      .collection("users")
      .doc(currentUser.uid)
      .onSnapshot((doc) => {
        let userInfo = doc.data();
        dispatch({
          type: "updateUserData",
          value: userInfo,
        });
        checkSessions(userInfo);
      });

    return firebaseListener;
  }

  function updateLoaded() {
    setTimeout(() => {
      setLoadedUserData(true);
    }, 300);
  }

  async function checkSessions(userInfo) {
    const session = localStorage.getItem("SessionID");
    const IP = await publicIpv4();

    if (
      (userInfo["session_1"].id == session && userInfo["session_1"].ip == IP) ||
      (userInfo["session_2"].id == session && userInfo["session_2"].ip == IP)
    ) {
      setUserIsAllowed(true);
      updateLoaded();
      return;
    }

    if (userInfo["session_1"].id == "free") {
      addSession("session_1");
      setUserIsAllowed(true);
      updateLoaded();
      return;
    }
    if (userInfo["session_2"].id == "free") {
      addSession("session_2");
      setUserIsAllowed(true);
      updateLoaded();
      return;
    }

    if (stateRef.current.reachedUI == true) {
      dispatch({
        type: "setReachedUI",
        value: false,
      });
      handleLogout();
    } else {
      setUserIsAllowed(false);
      updateLoaded();
    }
  }

  async function handleLogout() {
    setError("");
    localStorage.removeItem("SessionID");
    try {
      await logout();
      history.push("/login");
      alert("Your session has been terminated by another user!");
    } catch {
      setError("Failed to log out");
    }
  }

  async function addSession(sessionString) {
    let sessionID = localStorage.getItem("SessionID");
    const IP = await publicIpv4();

    if (sessionID) {
      const today = new Date();
      let textDate = today.toString();
      let newSession = {
        id: sessionID,
        timestamp: textDate,
        ip: IP,
      };
      db.collection("users")
        .doc(currentUser.uid)
        .update({
          [sessionString]: newSession,
        });
    }
  }

  return (
    <div>
      {loadedUserData ? (
        <div>
          {userIsAllowed ? (
            <Dashboard></Dashboard>
          ) : (
            <SessionDialog></SessionDialog>
          )}
        </div>
      ) : (
        <div
          style={{
            marginLeft: "auto",
            width: "50%",
            alignSelf: "center",
            paddingTop: "30%",
          }}
        >
          <Stack
            sx={{
              color: colors.lightColor1,
            }}
            spacing={2}
            direction="row"
          >
            <CircularProgress color="inherit" />
          </Stack>
        </div>
      )}
    </div>
  );
}

export default SessionManager;
