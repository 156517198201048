import React, { useRef, useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import logo from "../assets/logo_black.png";
import background from "../assets/background_2.png";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Box, Typography } from "@material-ui/core";
import { Card } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import { colors, fonts } from "./theme";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#8A1538",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#8A1538",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#8A1538",
    },
    "&:hover fieldset": {
      borderColor: "#8A1538",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#8A1538",
    },
  },
});

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      localStorage.setItem("SessionID", Math.floor(Math.random() * 10000000));
      history.push("/");
    } catch {
      setError("Failed to log in");
    }

    setLoading(false);
  }
  useEffect(() => {
    document.body.style.backgroundColor = "rgb(28,1,17)";
    document.body.style.backgroundImage = `url(${background})`;
    document.body.style.backgroundRepeat = "repeat";
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <>
      <Card
        style={{
          backgroundColor: `${colors.lightColor1}`,
          width: "20%",
          height: "200%",
          marginLeft: "10.5%",
          marginTop: "10%",
          marginBottom: "6%",
          borderRadius: "10px",
          opacity: "90%",
          boxShadow: `1px 1px 5px ${colors.lightColor1}`,
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "60%",
            maxHeight: "25%",
            paddingTop: "20px",
          }}
        />
        <center>
          <Typography style={{ padding: "15px", fontFamily: fonts.medium }}>
            Radio International Sound
          </Typography>
        </center>
        <form onSubmit={handleSubmit}>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "80%" },
            }}
            noValidate
            autoComplete="off"
            display="flex"
            flexWrap="wrap"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {error && (
              <div>
                <Alert
                  variant="outlined"
                  severity="error"
                  style={{
                    marginBottom: "6%",
                    backgroundColor: colors.lightColor1,
                  }}
                >
                  {error}
                </Alert>
              </div>
            )}
            <CssTextField
              id="email"
              label="Email"
              variant="outlined"
              inputRef={emailRef}
              required
            />
            <p />
            <CssTextField
              id="password"
              label="Password"
              variant="outlined"
              type="password"
              autoComplete="current-password"
              inputRef={passwordRef}
              required
            />
          </Box>
          <Button
            type="submit"
            disabled={loading}
            style={{
              margin: "2%",
              marginTop: "30px",
              marginBottom: "50px",
              color: `${colors.lightColor1}`,
              backgroundColor: `${colors.mediumColor}`,
              width: "80%",
              height: "50px",
              borderWidth: "0",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: "6px",
            }}
          >
            <Typography style={{ fontFamily: fonts.medium, paddingTop: "4px" }}>
              Login
            </Typography>
          </Button>
        </form>
      </Card>
    </>
  );
}
