import React, { useState, useEffect } from "react";
import { UserContext } from "../contexts/UserProvider";

import OvenPlayer from "ovenplayer";

const Videoplayer = (props) => {
  const [state, dispatch] = React.useContext(UserContext);

  let matchPort =
    state.streamingConfig[`Channel_${props.matchInfo.channel}`].port;
  let matchHost =
    state.streamingConfig[`Channel_${props.matchInfo.channel}`].dns;
  let streamName =
    state.streamingConfig[`Channel_${props.matchInfo.channel}`].streamName;

  useEffect(() => {
    // Initialize OvenPlayer
    try {
      const script = document.createElement("script");
      script.src = "https://cdn.jsdelivr.net/npm/hls.js@latest/dist/hls.min.js";
      script.async = true;
      document.body.appendChild(script);

      const player = OvenPlayer.create("player_id", {
        autoStart: true,
        autoFallback: true,
        mute: false,
        sources: [
          {
            type: "webrtc",
            file: `wss://${matchHost}:${matchPort}${streamName}`,
          },
          {
            type: "ll-hls",
            file: `https://${matchHost}:${matchPort}${streamName}/llhls.m3u8`,
          },
        ],
      });

      // Cleanup the player when the component unmounts
      return () => {
        document.body.removeChild(script);
        player.remove();
      };
    } catch (error) {
      console.error("Error initializing OvenPlayer:", error);
    }
  }, []);
  const playerContainerStyles1 = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const playerContainerStyles2 = {
    padding: "10px",
    width: "60%",
  };
  return (
    <div style={playerContainerStyles1}>
      <div style={playerContainerStyles2}>
        <div id="player_id"></div>
      </div>
    </div>
  );
};

export default Videoplayer;
